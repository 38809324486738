import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import colourScheme from '../theme/colour'

export const ThemeContext = React.createContext(colourScheme.green)
export const DataContext = React.createContext({})
export const MapContext = React.createContext(null)
export const UIContext = React.createContext({
  isSideOpen: false
})

const Store = ({children}) => {

  const content = useStaticQuery(graphql`
    query AllContentQuery {
      # SETTINGS
      allContentfulSettings (
        limit: 1
      ) {
        edges {
          node {
            title
            description
            about {
              childMarkdownRemark {
                html
              }
            }
            shortcuts {
              ...Location
            }
          }
        }
      }
      # EVENTS
      allContentfulEvent (
        sort: {
          fields: [startDate],
          order: DESC
        }
      ) {
        edges {
          node {
            ...Event
          }
        }
      }
      # HISTORY
      allContentfulPlace (
        sort: {
          fields: [historicalDate],
          order: ASC
        }
      ) {
        edges {
          node {
            ...Place
          }
        }
      }
      # CATEGORIES (FOR MAP KEY)
      allContentfulCategory (
        sort: {
          fields: [title],
          order: ASC
        }
      ) {
        edges {
          node {
            ...Category
          }
        }
      }
    }
  `)

  const [theme, setTheme] = useState(colourScheme.green)
  const [data, setData] = useState(content)
  const [UI, setUI] = useState({
    isSideOpen: false
  })
  const [map, setMap] = useState(null)

  return (
    <DataContext.Provider value={[data, setData]}>
      <ThemeContext.Provider value={[theme, setTheme]}>
        <UIContext.Provider value={[UI, setUI]}>
          <MapContext.Provider value={[map, setMap]}>
            {children}
          </MapContext.Provider>
        </UIContext.Provider>
      </ThemeContext.Provider>
    </DataContext.Provider>
  )
}

export default Store

export const eventFragment = graphql`
  fragment Event on ContentfulEvent {
    id
    title
    slug
    description {
      childMarkdownRemark {
        html
      }
    }
    image {
      title
      description
      fluid(maxWidth: 500) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    externalLinkUrl
    externalLinkLabel
    startDate
    locations {
      ...Location
    }
    markers {
      ...Location
    }
    heading {
      childMarkdownRemark {
        html
      }
    }
    body {
      childMarkdownRemark {
        html
      }
    }
    category {
      ...Category
    }
  }
`

export const placeFragment = graphql`
  fragment Place on ContentfulPlace {
    id
    title
    slug
    heading {
      childMarkdownRemark {
        html
      }
    }
    image {
      title
      description
      fluid(maxWidth: 500) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    category {
      ...Category
    }
    location {
      ...Location
    }
    moments {
      id
      date
      body {
        childMarkdownRemark {
          html
        }
      }
      summary {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export const categoryFragment = graphql`
  fragment Category on ContentfulCategory {
    id
    title
    slug
    icon {
      file {
        url
      }
    }
    mapIcon {
      file {
        url
      }
    }
    mapIconActive {
      file {
        url
      }
    }
  }
`

export const locationFragment = graphql`
  fragment Location on ContentfulLocation {
    id
    coordinates {
      lon
      lat
    }
    address {
      address
    }
    description {
      description
    }
  }
`
