import mapStyles from './map-styles'

export default {
  blue: {
    light: '#F2F5F9',
    medium: '#DFE7F0',
    dark: '#96B8DE',
    mapStyle: mapStyles.blue
  },
  red: {
    light: '#EDE0DB',
    medium: '#E2C7BF',
    dark: '#99462A',
    mapStyle: mapStyles.red
  },
  green: {
    light: '#EBF4F1',
    medium: '#DBEBE5',
    dark: '#7CC4A9',
    mapStyle: mapStyles.green
  },
}
