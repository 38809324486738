import React, { useContext } from "react"
import { UIContext } from '../app/store'
import "../css/app.scss"

const Layout = ({ children }) => {

  const [UI] = useContext(UIContext);

  return (
    <main className={`${UI && UI.isSideOpen ? 'side-open' : 'side-closed'}`}>
      {children}
    </main>
  )
}

export default Layout
